import Header from "./components/Header";
import Footer from "./components/Footer";
import { Routes, Route } from "react-router-dom";
import Main from "./components/Main";
import Contact from "./components/Contact";
import NotFound from "./components/NotFound";
import ScrollToTopButton from "./components/ScrollToTopButton";
import KonamiCodeHandler from "./components/KonamiCodeHandler";

const App = () => {
  return (
    <div className="bg-black font-sans text-white">
      <Header />
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/page/:page" element={<Main />} />
        <Route path="/contact" element={<Contact />} />
        {/* Wildcard route for 404 */}
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
      <ScrollToTopButton />
      <KonamiCodeHandler />
    </div>
  );
};

export default App;
