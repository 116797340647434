import PostCommentButton from "./PostCommentButton";

interface VoteOption {
  image: string;
  label: string;
  votes: number;
}

interface VoteResultProps {
  title: string;
  data: VoteOption[];
}

const VoteResult: React.FC<VoteResultProps> = ({ title, data }) => {
  // Sort data by number of votes
  const sortedData = data.sort((a, b) => b.votes - a.votes);

  return (
    <div className="flex flex-col items-center px-4 py-8">
      {/* Title */}
      <h2 className="w-full text-xl font-bold mb-8">
        <span className="block w-4/5 h-[1px] bg-gradient-to-r from-red-regular to-red-light mx-auto"></span>
        <span className="block text-lg font-bold text-center leading-snug py-2">
          {title}
        </span>
        <span className="block w-4/5 h-[1px] bg-gradient-to-l from-red-regular to-red-light mx-auto"></span>
      </h2>

      {/* Result */}
      {sortedData.map((item, index) => (
        <div key={index} className="w-full flex mb-2">
          <div
            className={`w-5 flex justify-center items-center  text-white 
            ${
              index < 3
                ? "text-shadow-neon text-4xl"
                : index < 10
                ? "text-2xl"
                : "text-xl"
            }`}
          >
            <span className="crimson">{index + 1}</span>
          </div>
          <div className="flex-grow pl-2">
            <div className="flex items-center">
              <img
                src={`/assets/images/${item.image}`}
                alt={item.label}
                className="w-8 mr-2"
              />
              <span>{item.label}</span>
            </div>

            <div className="flex justify-start items-center flex-nowrap">
              <div
                className={`h-3 ${"bg-gradient-to-r from-red-700 to-red-light"}`}
                style={{
                  width: `${(item.votes / sortedData[0].votes) * 100}%`,
                }}
              ></div>
              <span
                className={`text-white text-[12px] w-[40px] leading-none ${
                  item.votes > 0 && "pl-1"
                } `}
              >
                {item.votes}
              </span>
            </div>
          </div>
        </div>
      ))}

      <div className="flex justify-center items-center mt-5 block">
        <PostCommentButton buttonText="Vote Now" fontSize="small" />
      </div>
    </div>
  );
};

export default VoteResult;
