import { Link } from "react-router-dom";

interface PaginationProps {
  currentPage: number;
  totalPosts: number;
  postsPerPage: number;
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPosts,
  postsPerPage,
}) => {
  const pageNumbers: number[] = [];
  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <nav className="flex justify-center items-center my-5">
      <ul className="pagination flex">
        {/* Previous Button */}
        {currentPage > 1 && (
          <li className="page-item mx-1">
            <Link
              to={`/page/${currentPage - 1}`}
              className="page-link flex justify-center items-center bg-white text-red-light rounded leading-none text-xs sm:text-base min-w-[25px] min-h-[25px] sm:min-w-[35px] sm:min-h-[35px]"
            >
              &lt; {/* Previous arrow */}
            </Link>
          </li>
        )}

        {/* Page Numbers */}
        {pageNumbers.map((number) => (
          <li key={number} className="page-item mx-1">
            <Link
              to={`/page/${number}`}
              className={`page-link ${
                currentPage === number
                  ? "bg-red-light text-white cursor-default"
                  : "bg-white text-red-light"
              } rounded leading-none text-xs sm:text-base flex justify-center items-center min-w-[25px] min-h-[25px] sm:min-w-[35px] sm:min-h-[35px]`}
            >
              {number}
            </Link>
          </li>
        ))}

        {/* Next Button */}
        {currentPage < Math.ceil(totalPosts / postsPerPage) && (
          <li className="page-item mx-1">
            <Link
              to={`/page/${currentPage + 1}`}
              className="page-link flex justify-center items-center bg-white text-red-light rounded leading-none text-xs sm:text-base min-w-[25px] min-h-[25px] sm:min-w-[35px] sm:min-h-[35px]"
            >
              &gt; {/* Next arrow */}
            </Link>
          </li>
        )}
      </ul>
    </nav>
  );
};

export default Pagination;
