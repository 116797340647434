import React, { useState, useEffect } from "react";
import axios from "axios";
import Post from "./Post";
import Pagination from "./Pagination";
import DashedBorder from "./DashedBorder";
import SolidBorder from "./SolidBorder";
import PostForm from "./PostForm";
import Loading from "./Loading";

interface PostsProps {
  page?: string;
}

interface PostData {
  id: number;
  posted_by: string;
  created_at: string;
  favourite_character_id: number;
  favourite_siren_series_id: number;
  comment: string;
  likes: number;
  character: CharacterInfo;
  siren_series: SirenSeriesInfo;
}

interface CharacterInfo {
  id: number;
  name: string;
  full_name: string;
  age: number;
  gender: string;
  occupation: string;
  photo1: string;
  photo2: string;
  photo3: string;
  photo4: string;
}

interface SirenSeriesInfo {
  id: number;
  name: string;
  image: string;
}

const Posts: React.FC<PostsProps> = ({ page }) => {
  const [currentPage, setCurrentPage] = useState<number>(parseInt(page || "1"));
  const [posts, setPosts] = useState<PostData[]>([]);
  const [totalPosts, setTotalPosts] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const postsPerPage: number = 10; // Change as needed

  useEffect(() => {
    setLoading(true);
    setCurrentPage(parseInt(page || "1"));
  }, [page]);

  useEffect(() => {
    fetchPosts();
  }, [currentPage]);

  const fetchPosts = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL_PROD}/posts?page=${currentPage}&limit=${postsPerPage}`
      );
      setPosts(response.data.posts);
      setTotalPosts(response.data.totalPosts);
      setLoading(false);
    } catch (error: any) {
      console.error("Error fetching posts:", error.message);
      setLoading(false);
    }
  };

  return (
    <div>
      {totalPosts > postsPerPage && (
        <>
          <DashedBorder />
          <Pagination
            currentPage={currentPage}
            totalPosts={totalPosts}
            postsPerPage={postsPerPage}
          />
        </>
      )}

      {loading ? (
        <div className="min-h-[1000px]">
          <Loading />
        </div>
      ) : (
        <>
          <SolidBorder />
          {posts.map((post) => (
            <Post
              key={post.id}
              id={post.id}
              postedBy={post.posted_by}
              postedTime={post.created_at}
              comment={post.comment}
              likes={post.likes}
              character={post.character}
              sirenSeries={post.siren_series}
            />
          ))}
          <DashedBorder />
        </>
      )}

      {/* <Pagination
        currentPage={currentPage}
        totalPosts={totalPosts}
        postsPerPage={postsPerPage}
      />
      <SolidBorder /> */}
      <PostForm />
    </div>
  );
};

export default Posts;
