import { useState, useEffect } from "react";

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.scrollY > 100) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  return (
    <button
      className={`w-[50px] h-[50px] block fixed bottom-3 right-3 bg-gray-800 text-white p-2 rounded-full transition-opacity ${
        isVisible ? "opacity-100" : "opacity-0"
      }`}
      style={{ boxShadow: "0 0 5px 3px rgba(139, 39, 17, 0.5)" }}
      onClick={scrollToTop}
    >
      TOP
    </button>
  );
};

export default ScrollToTopButton;
