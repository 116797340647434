const OmikujiData = [
  {
    fortune: "Great Fortune",
    message:
      "The warm light of a spring day resonates with the thoughts of the gods.",
    advice: [
      {
        Wish: "Will go as desired",
        Visitor: "Will come soon",
        "Lost item": "Will definitely be found",
        Travel: "Treasure awaits in distant places",
        Business: "Profitable, good for selling",
        Study: "Study with peace of mind",
        Dispute: "Will win as desired",
        Romance: "Will cross the line",
        Illness: "Will definitely recover",
        Engagement: "Blessing of children",
      },
    ],
  },
  {
    fortune: "Good Fortune",
    message: "There are mountains and valleys, with both joy and hardship.",
    advice: [
      {
        Wish: "Don't underestimate, or you'll fail",
        Visitor: "Obstacles exist, and they won't come",
        "Lost item": "Is in a high place",
        Travel: "Good if nearby",
        Business: "Wait for sales, it's not a loss",
        Study: "Good if done secretly",
        Dispute: "Don't be careless",
        Romance: "Will soon be advantageous",
        Illness: "Heavy, choose a doctor carefully",
        Engagement: "Parents' opposition exists",
      },
    ],
  },
  {
    fortune: "Misfortune",
    message: "Moving forward guided by the faint moonlight on a dark path.",
    advice: [
      {
        Wish: "Must endure patiently",
        Visitor: "Don't rely on, wait without expectations",
        "Lost item": "Will not appear, hidden away",
        Travel: "Sock has a hole",
        Business: "Unexpected misfortune",
        Study: "There are obstacles",
        Dispute: "Stand after considering the opponent",
        Romance: "Good partner, but dangerous",
        Illness: "Heavy, call family members",
        Engagement: "No prospects",
      },
    ],
  },
  {
    fortune: "Great Misfortune",
    message: "Without seeing spring, the withered winter grass decays.",
    advice: [
      {
        Wish: "Wishing alone is futile",
        Visitor: "Even if called, won't come",
        "Lost item": "Known only to women",
        Travel: "All sides blocked",
        Business: "One-way downhill",
        Study: "Remembering but forgetting",
        Dispute: "Cry and apologize",
        Romance: "Will lead to conflict",
        Illness: "Leads to death",
        Engagement: "No prospects for marriage",
      },
    ],
  },
];

export default OmikujiData;
