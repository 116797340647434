import Container from "./Container";
import TitleImage from "../assets/images/siren-title.jpg";
import PostCommentButton from "./PostCommentButton";

const Header = () => {
  return (
    <header>
      <Container>
        <div className="max-w-[600px] mx-auto">
          <h1>
            <a href="/" className="w-full block px-10 py-5">
              <img className="" src={TitleImage} alt="Forbidden SIREN" />
            </a>
          </h1>
          <p className="text-center text-base mb-2">100% Unofficial Fan Site</p>
          <p className="text-center text-sm">
            Welcome to this 100% UNOFFICIAL fan site dedicated to all the
            passionate SIREN fans out there! This site is made with love by a
            fellow SIREN enthusiast who wants to create a space for us to share
            our adoration for this incredible game.
          </p>
          <div className="flex justify-center items-center my-5">
            <PostCommentButton />
          </div>
        </div>
      </Container>
    </header>
  );
};

export default Header;
