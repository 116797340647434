import { useState } from "react";
import omikujiData from "../data/OmikujiData";
import ShrineMark from "../assets/images/mizuhiruko_shrine_mark.svg";
import TriiGateImg from "../assets/images/jinja-trii-gate.png";

const Omikuji = () => {
  const [showModal, setShowModal] = useState(false);
  const [result, setResult] = useState<any>();

  // Function to handle drawing Omikuji
  const drawOmikuji = () => {
    // Calculate random number between 1 and 100
    const randomNumber = Math.floor(Math.random() * 100) + 1;

    // Determine result based on random number
    let selectedResult;
    if (randomNumber <= 5) {
      selectedResult = omikujiData[0];
    } else if (randomNumber <= 20) {
      selectedResult = omikujiData[1];
    } else if (randomNumber <= 50) {
      selectedResult = omikujiData[2];
    } else {
      selectedResult = omikujiData[3];
    }

    // Update state with selected result
    setResult(selectedResult);
    // Show modal
    setShowModal(true);
  };

  // Function to close modal
  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="w-full">
      <div className="flex justify-start items-center">
        <img src={TriiGateImg} alt="torii gate" className="w-12" />
        <button
          onClick={drawOmikuji}
          className="bg-[#8b7500] py-2 px-4 flex-grow text-xm ml-2 yozakura"
        >
          Draw Omikuji
        </button>
      </div>

      {/* Modal */}
      {showModal && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-10 zen">
          <div className="modal-overlay fixed top-0 left-0 w-full h-full bg-black opacity-90"></div>
          <div className="modal bg-red-light text-white p-4 sm:p-8 relative w-4/5 max-w-[800px]">
            <button
              className="absolute -top-5 -right-5 text-white text-xl bg-black w-10 h-10 flex justify-center items-center border-red-light border-2 p-3 rounded-full"
              onClick={closeModal}
            >
              &times;
            </button>
            <div className="bg-white text-black p-5 max-h-[50vh] sm:max-h-none overflow-y-scroll sm:overflow-y-auto">
              <div className="flex flex-col sm:flex-row">
                {/* Shrine Name */}
                <div className="order-4 sm:order-1 sm:mr-10 flex items-end justify-end">
                  <span className="text-lg sm:text-xl sm:[writing-mode:vertical-lr]">
                    水蛭子神社
                  </span>
                </div>

                {/* Fortune Advice */}
                <div className="order-3 sm:order-2  flex-grow sm:mr-4 mb-4 sm:mb-0">
                  <span className="mb-2 block text-sm italic">
                    {result.message}
                  </span>
                  <div>
                    {result.advice.map((adviceSet: any, index: number) => (
                      <div key={index}>
                        {Object.entries(adviceSet).map(([key, value]) => (
                          <div
                            key={key}
                            className="flex mb-1 text-xs sm:text-sm"
                          >
                            <span className="font-semibold mr-2 whitespace-nowrap">
                              {key}:
                            </span>
                            <span>{value as string}</span>
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                </div>

                {/* Fortune */}
                <div className="order-2 sm:order-3 w-4/5 m-auto sm:w-1/4 mt-auto mb-4 sm:mb-0">
                  <p className="my-4 text-center">Fortune</p>
                  <div className="w-full relative">
                    <h3 className="yozakura z-10 text-2xl leading-[1.3] text-center leading-none font-bold absolute top-1/2 left-1/2 -translate-x-1/2  -translate-y-1/2">
                      {result.fortune}
                    </h3>
                    <img
                      src={ShrineMark}
                      className="w-full opacity-80"
                      alt="mizuhiruko shrine mark"
                    />
                  </div>
                </div>

                {/* Omikuji Title */}
                <div className="order-1 sm:order-4 flex items-center justify-center sm:ml-10 mb-4 sm:mb-0">
                  <span className="text-3xl sm:text-4xl font-bold sm:[writing-mode:vertical-lr]">
                    おみくじ
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Omikuji;
