import Container from "./Container";
import NotFoundImage from "../assets/images/siren-shibito-woman.png";

const NotFound = () => {
  return (
    <div className="mx-auto my-10 py-10">
      <Container>
        <h1 className="text-2xl sm:text-4xl font-bold text-center text-red-regular mb-4">
          <span className="block w-full text-[300%] mb-5 sm:mb-10">404</span>
          Page Not Found
        </h1>
        <div className="text-lg text-center flex flex-col justify-center items-center">
          Sorry, the page you are looking for does not exist...
          <img
            src={NotFoundImage}
            alt=""
            className="w-1/2 sm:w-1/5"
            style={{
              animationName: "tiltAnimation",
              animationDuration: "4s",
              animationIterationCount: "infinite",
              animationTimingFunction: "linear",
            }}
          />
          <div className="my-5 sm:my-10">
            <a
              href="/"
              className="text-center inline-block bg-red-dark py-2 px-4 hover:bg-red-medium text-white border border-white transition duration-300"
            >
              Go Back to the Homepage
            </a>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default NotFound;
