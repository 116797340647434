import { useState, useEffect } from "react";
import WhiteLine from "../assets/images/white-line3.svg";
import axios from "axios";
import WaterBottleSVG from "./WaterBottleSVG";
import { customDateFormat } from "../utils/dateUtils";
import ThumbsUpIcon from "../assets/images/thumbs-up.png";
import RedBgImage from "../assets/images/red-bg.jpg";
import ManaCrossImage from "../assets/images/mana-cross.png";

interface PostProps {
  id: number;
  postedBy: string;
  postedTime: string;
  character: CharacterInfo;
  sirenSeries: SirenSeriesInfo;
  comment: string;
  likes: number;
}

interface CharacterInfo {
  id: number;
  name: string;
  full_name: string;
  age: number;
  gender: string;
  occupation: string;
  photo1: string;
  photo2: string;
  photo3: string;
  photo4: string;
}

interface SirenSeriesInfo {
  id: number;
  name: string;
  image: string;
}

const Post: React.FC<PostProps> = ({
  id,
  postedBy,
  postedTime,
  character,
  sirenSeries,
  comment,
  likes,
}) => {
  const [formattedTime, setFormattedTime] = useState("");
  const [liked, setLiked] = useState(false);
  const [likeCount, setLikeCount] = useState(likes);

  useEffect(() => {
    //Format the postedTime string when the component mounts
    const formatted = customDateFormat(postedTime);
    setFormattedTime(formatted);
  }, [postedTime]);

  useEffect(() => {
    // Check if the post is already liked in local storage
    const alreadyLiked = localStorage.getItem(`post_${id}_liked`);
    if (alreadyLiked === "true") {
      setLiked(true);
    }
  }, []);

  const handleLikeClicked = () => {
    if (!liked) {
      setLiked(true);
      setLikeCount((prevCount) => prevCount + 1); // Increment likeCount

      // Save in local storage
      localStorage.setItem(`post_${id}_liked`, "true");

      // Send request to server to update like count
      axios
        .post(`${process.env.REACT_APP_API_BASE_URL_PROD}/post-like`, {
          postId: id,
        })
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.error("Error updating like count:", error);
        });
    }
  };

  return (
    <div
      className="p-6 my-6 bg-gradient-to-b from-black via-transparent to-red-dark"
      style={{
        background: "linear-gradient(180deg, rgb(0, 0, 0) 10%, #440808 95%)",
        backgroundImage: `url(${RedBgImage})`,
        backgroundSize: "cover",
      }}
    >
      <div className="mb-5">
        <span className="text-xs">Posted By</span>
        <div className="sm:flex flex-nowrap justify-start items-center">
          <span className="block text-sm font-bold mb-2 sm:mb-0 sm:mr-4">
            {postedBy}
          </span>
          <span className="block text-xs text-right sm:text-right">
            {formattedTime}
          </span>
        </div>
      </div>
      <div className="">
        <div className="flex flex-nowrap justify-start items-center mb-2">
          <span className="texg-lg sm:text-xl whitespace-nowrap mr-4 sm:mr-8">
            My favourite character is
          </span>
          <span className="block bg-white h-[1px] sm:h-[2px] w-full"></span>
        </div>
      </div>

      {character && (
        <div className="">
          {/* Character Name */}
          <div className="text-[10vw] sm:text-7xl leading-none flex justify-start items-center flex-nowrap mb-3">
            <span className="font-bold mr-[1vw] sm:mr-5 whitespace-nowrap">
              {character.name.split(" ")[0]}
            </span>
            <span className="tracking-tighter whitespace-nowrap">
              {character.name.split(" ")[1]}
            </span>
          </div>

          {/* Character Image Front */}
          <div className="sm:flex justify-start items-start relative mb-2">
            <div className="flex-none w-2/5 sm:w-1/4 border-white  border-solid border-2 sm:border-4 sm:mr-2 mb-2 sm:mb-0 relative">
              {character.photo1 && (
                <img
                  src={`/assets/images/${character.photo1}`}
                  alt="Character Photo Main"
                  className={`w-full`}
                />
              )}
            </div>

            {/* Character Bio */}
            <div className="w-full">
              <div className="border-white  border-solid border-2 sm:border-4 flex h-full flex-grow">
                {/* Character Image Profile */}
                <div className="flex-none w-1/5 bg-black relative">
                  {character.photo2 && (
                    <img
                      src={`/assets/images/${character.photo2}`}
                      alt="Character Photo Profile"
                      className={`w-full`}
                    />
                  )}
                </div>

                {/* Character Information */}
                <div className="flex flex-col flex-grow border-solid border-l-2 sm:border-l-4 border-r-2 sm:border-r-4 text-[3vw] sm:text-base">
                  <span className="flex-grow mx-1">
                    Name:{" "}
                    <b>
                      {character.full_name
                        ? character.full_name
                        : character.name}
                    </b>
                  </span>
                  <div className="flex flex-grow border-solid border-t-2 sm:border-t-4 border-b-2 sm:border-b-4">
                    <span className="mx-1">
                      Age: <b>{character.age}</b>
                    </span>
                    <span className="block h-full bg-white w-[2px] sm:w-1"></span>
                    <span className="mx-1">
                      Gender: <b>{character.gender}</b>
                    </span>
                  </div>
                  <span className="flex-grow mx-1">
                    Occupation: <b>{character.occupation}</b>
                  </span>
                </div>
                <div className="flex-none w-1/5 bg-black"></div>
              </div>

              {/* Message */}
              <div className="w-2/3 sm:w-5/6 ml-auto border-solid border-[1px] border-white mt-3 h-full relative pl-3">
                <span className="block bg-yellow absolute top-[-1px] left-[-1px] w-2 sm:w-3 h-[calc(100%+2px)]"></span>
                <div className="flex justify-start items-center flex-nowrap p-1 pb-4">
                  <span className="w-2 sm:w-4 h-2 sm:h-4 bg-yellow block"></span>
                  <span className="text-yellow text-xs sm:text-sm ml-1 font-bold tracking-wide">
                    Message
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/* White Lines */}
          <div className="w-[25%] sm:w-[35%] relative">
            <span className="first-border w-[90%] h-[1px] sm:h-[2px] bg-white block relative">
              <img
                src={WhiteLine}
                alt=""
                className="w-[30%] sm:w-[15%] absolute right-[1px] -bottom-full transform translate-x-full translate-y-[-2px]"
              />
            </span>
          </div>
        </div>
      )}

      {/* Message */}
      <div className="relative p-3">
        <span className="w-[1px] sm:w-[2px] bg-white h-20 block absolute top-0 left-0"></span>
        <span className="w-[1px] sm:w-[2px] bg-white h-20 block absolute bottom-0 right-0"></span>
        <span className="w-[35%] bg-white h-[1px] sm:h-[2px] block absolute bottom-0 right-0"></span>

        <span className="text-yellow text-base sm:text-lg block font-bold tracking-wide mb-1 sm:mb-2">
          Message
        </span>
        <p className="mb-5 text-[15px] sm:text-base">{comment}</p>

        {sirenSeries && (
          <div className="sm:flex justify-start items-end flex-nowrap mb-2">
            <span className="block font-bold mb-2 sm:mb-0 sm:mr-2 leading-none">
              My Favourite SIREN Series:
            </span>
            <div className="flex justify-start items-end">
              <span className="mr-2 leading-none">{sirenSeries.name}</span>
              <img
                className="w-10"
                src={`/assets/images/${sirenSeries.image}`}
                alt={sirenSeries.name}
              />
            </div>
          </div>
        )}
      </div>
      {/* Like */}
      <div className="mt-10">
        <div className="flex justify-end items-end">
          <div className="block flex-grow overflow-hidden relative">
            <img
              alt=""
              src={ManaCrossImage}
              className={`w-8 transition-transform duration-500 transform ${
                likeCount >= 100 ? "translate-y-0" : "translate-y-[100%]"
              }`}
            />
          </div>
          <button
            className={`text-white text-base px-2 py-1 mb-[2px] mr-2 rounded leading-none flex justify-center items-center ${
              liked
                ? "bg-gray-400 opacity-80"
                : "bg-red-light hover:bg-red-regular cursor-pointer transform duration-300"
            }`}
            disabled={liked}
            onClick={handleLikeClicked}
          >
            <img src={ThumbsUpIcon} alt="" className="w-4 mr-1" />
            <span>LIKE</span>
          </button>
          <WaterBottleSVG id={id} likes={likeCount} />
        </div>
      </div>
    </div>
  );
};

export default Post;
