import LoadingImg from "../assets/images/loading.webp";

const Loading: React.FC = () => {
  return (
    <div className="flex justify-center items-center bg-black w-full h-[300px]">
      <img src={LoadingImg} alt="" />
    </div>
  );
};

export default Loading;
