import Container from "./Container";
import RightSidebar from "./RightSidebar";
import Posts from "./Posts";
import { useParams } from "react-router-dom";

const Main = () => {
  const { page } = useParams<{ page?: string }>();

  return (
    <main>
      <Container>
        <div className="md:flex md:flex-nowrap gap-x-8">
          <div className="md:w-2/3">
            <section>
              <Posts page={page} />
            </section>
          </div>
          <div className="md:w-1/3">
            <RightSidebar />
          </div>
        </div>
      </Container>
    </main>
  );
};

export default Main;
