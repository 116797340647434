import React, { useEffect, useState } from "react";

const KonamiCodeHandler: React.FC = () => {
  // Define the Konami Code sequence
  const konamiCodeSequence = [
    "ArrowUp",
    "ArrowUp",
    "ArrowDown",
    "ArrowDown",
    "ArrowLeft",
    "ArrowRight",
    "ArrowLeft",
    "ArrowRight",
    "KeyB",
    "KeyA",
    "Enter",
  ];

  // Initialize state to track progress through the sequence
  const [konamiCodeIndex, setKonamiCodeIndex] = useState<number>(0);

  // Add event listener to listen for key presses
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      // Check if the pressed key matches the next key in the sequence
      if (event.code === konamiCodeSequence[konamiCodeIndex]) {
        // Move to the next key in the sequence
        setKonamiCodeIndex((prevIndex) => prevIndex + 1);
        // If all keys in the sequence have been pressed, trigger the Konami Code action
        if (konamiCodeIndex === konamiCodeSequence.length - 1) {
          triggerKonamiCodeAction();
          // Reset index to start over for subsequent attempts
          setKonamiCodeIndex(0);
        }
      } else {
        // Reset index if a wrong key is pressed
        setKonamiCodeIndex(0);
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [konamiCodeIndex, konamiCodeSequence]);

  // Function to trigger the Konami Code action (you can replace this with your own logic)
  const triggerKonamiCodeAction = () => {
    // Add your custom Konami Code action logic here
    alert(
      "Did you just try the Konami Code? 😏 Thanks, but I haven't decided what to do for this event yet. I appreciate your effort, though. Check back later, and I might have something ready by then. Ciao!"
    );
  };

  return null; // This component doesn't render anything visible
};

export default KonamiCodeHandler;
