import React, { useState, useEffect, useMemo, useRef } from "react";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";

interface Character {
  id: number;
  created_at: string;
  name: string;
  age: string;
  gender: string;
  occupation: string;
  weapon: number;
  photo1: string;
  photo2: string;
  votes: number;
}

interface SirenSeries {
  id: number;
  created_at: string;
  name: string;
  image: string;
  votes: number;
}

const PostForm = () => {
  const [characters, setCharacters] = useState<Character[]>([]);
  const [sirenSeries, setSirenSeries] = useState<SirenSeries[]>([]);
  const [yourName, setYourName] = useState<string>("");
  const [selectedCharacter, setSelectedCharacter] = useState<number | null>(
    null
  );
  const [selectedSIRENSeries, setSelectedSIRENSeries] = useState<number | null>(
    null
  );
  const [comment, setComment] = useState<string>("");
  const [honeypotValue, setHoneypotValue] = useState<string>("");
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [submissionStatus, setSubmissionStatus] = useState<string | null>(null);

  // reCAPTCHA setting
  const [reCaptchaToken, setReCaptchaToken] = useState("");
  const handleRecaptcha = (value: any) => {
    setReCaptchaToken(value);
  };

  // Check if the user has already submitted the form
  useEffect(() => {
    const submitted = localStorage.getItem("formSubmitted");
    if (submitted) {
      setSubmissionStatus("submitted");
    } else {
      setSubmissionStatus("initial");
    }
  }, []);

  // Function to fetch characters data from backend
  const fetchCharacters = useMemo(() => {
    return async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL_PROD}/characters`
        );
        setCharacters(response.data);
      } catch (error: any) {
        console.error("Error fetching characters:", (error as any).message);
      }
    };
  }, []);

  // Function to fetch SIREN Serires data from backend
  const fetchSIRENSeries = useMemo(() => {
    return async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL_PROD}/siren-series`
        );
        setSirenSeries(response.data);
      } catch (error: any) {
        console.error("Error fetching SIREN series:", error.message);
        return []; // Return an empty array in case of an error
      }
    };
  }, []);

  // Fetch characters data when the component mounts
  useEffect(() => {
    fetchCharacters();
  }, [fetchCharacters]);

  // Fetch SIRNE serires data when component mounts
  useEffect(() => {
    fetchSIRENSeries();
  }, [fetchSIRENSeries]);

  const handleYourNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setYourName(e.target.value);
    setErrors({ ...errors, yourName: "" });
  };

  const handleCharacterClick = (characterIndex: number) => {
    setSelectedCharacter(characterIndex);
    setErrors({ ...errors, selectedCharacter: "" });
  };

  const handleSIRENSeriesClick = (series: number) => {
    setSelectedSIRENSeries(series);
    setErrors({ ...errors, selectedSIRENSeries: "" });
  };

  const handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComment(e.target.value);
    setErrors({ ...errors, comment: "" });
  };

  const handleHoneypotChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHoneypotValue(e.target.value);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let hasError = false;
    const newErrors: { [key: string]: string } = {};

    // Honeypot validation
    if (honeypotValue) {
      console.log("Spam detected!");
      return; // Prevent form submission
    }

    // Validation
    if (!yourName.trim()) {
      newErrors.yourName = "Your name is required.";
      hasError = true;
    } else if (yourName.length > 20) {
      newErrors.yourName = "Your name should not exceed 20 characters.";
      hasError = true;
    }
    if (selectedCharacter === null || selectedCharacter === undefined) {
      newErrors.selectedCharacter = "Please select a character.";
      hasError = true;
    }
    if (!selectedSIRENSeries) {
      newErrors.selectedSIRENSeries = "Please select a SIREN series.";
      hasError = true;
    }
    if (!comment.trim()) {
      newErrors.comment = "Comment is required.";
      hasError = true;
    } else if (comment.length < 20) {
      newErrors.comment = "Comment should be at least 20 characters long.";
      hasError = true;
    } else if (comment.length > 1000) {
      newErrors.comment = "Comment should not exceed 1000 characters.";
      hasError = true;
    } else if (/http(s)?:\/\/|<|>/.test(comment)) {
      newErrors.comment = "URLs and HTML tags are not allowed.";
      hasError = true;
    }
    if (!reCaptchaToken) {
      newErrors.recaptcha = "Complete the reCAPTCHA verification.";
      hasError = true;
    }

    if (hasError) {
      setErrors(newErrors);
      return; // Prevent form submission
    }

    // Submission to the server
    try {
      await axios.post(`${process.env.REACT_APP_API_BASE_URL_PROD}/post`, {
        name: yourName,
        characterId: Number(selectedCharacter),
        sirenSeriesId: Number(selectedSIRENSeries),
        comment: comment,
        token: reCaptchaToken,
      });
      localStorage.setItem("formSubmitted", "true");
      setSubmissionStatus("submitted");
      setYourName("");
      setSelectedCharacter(null);
      setSelectedSIRENSeries(null);
      setComment("");
      setErrors({});
    } catch (error: any) {
      console.error("Error submitting form:", error.message);
      setSubmissionStatus("error");
    }
  };

  return (
    <div>
      <form
        className="my-6 border border-red-dark bg-[#2f0303] p-6"
        onSubmit={handleSubmit}
        id="form"
      >
        {/* Thank-you Message */}
        {submissionStatus === "submitted" && (
          <div className="mb-10">
            <span className="text-center block text-xl">
              Thank you for submitting your comment!
              <i className="block text-sm not-italic">
                (If you see this comment, you have already posted a comment
                before.)
              </i>
            </span>
          </div>
        )}

        {/* Name */}
        <div className="mb-7">
          <div className="sm:flex justify-start items-center">
            <label
              htmlFor="yourName"
              className="block text-base sm:text-lg sm:mr-4 mb-1 sm:mb-0 whitespace-nowrap"
            >
              Your Name (Nickname)
            </label>
            <input
              type="text"
              id="yourName"
              name="yourName"
              maxLength={20}
              placeholder="Enter your name"
              value={yourName}
              onChange={handleYourNameChange}
              disabled={submissionStatus === "submitted"}
              className={
                "px-3 py-1 text-black focus:outline-none border border-gray-500 focus:ring w-full sm:w-auto"
              }
              style={{
                background:
                  submissionStatus === "submitted"
                    ? "repeating-linear-gradient(45deg, black, black 10px, #444 10px, #444 11px)"
                    : "",
              }}
            />
          </div>
          {errors.yourName && (
            <div className="text-red-regular text-sm mt-1">
              {errors.yourName}
            </div>
          )}
        </div>

        {/* Favourite Character */}
        <div className="mb-7">
          <label
            htmlFor="favouriteCharacter"
            className="block text-base sm:text-lg mb-2"
          >
            Your Favourite Character (Choose One)
          </label>
          <div className="grid grid-cols-3 sm:grid-cols-5 gap-2">
            {characters &&
              characters.map((character) => (
                <div
                  key={character.id}
                  className={`border border--500 ${
                    selectedCharacter === character.id
                      ? "border border--500 bg-red-light border-red-light"
                      : "bg-gray-200"
                  } ${
                    submissionStatus !== "submitted"
                      ? "cursor-pointer"
                      : "cursor-default"
                  }`}
                  onClick={
                    submissionStatus !== "submitted"
                      ? () => handleCharacterClick(character.id)
                      : undefined
                  }
                >
                  <img
                    src={`/assets/images/${character.photo1}`}
                    alt="Character Photo"
                  />
                  <span
                    className={`block text-center leading-[1.1] py-2 px-1 whitespace-nowrap tracking-tighter text-[3vw] sm:text-[13px] font-semibold ${
                      selectedCharacter === character.id
                        ? "text-white"
                        : "text-black "
                    }`}
                  >
                    {character.name}
                  </span>
                </div>
              ))}
          </div>
          {errors.selectedCharacter && (
            <div className="text-red-regular text-sm mt-1">
              {errors.selectedCharacter}
            </div>
          )}
        </div>

        {/* Favourite SIREN Series */}
        <div className="mb-7">
          <label
            htmlFor="sirenSeries"
            className="block text-base sm:text-lg mb-2"
          >
            Which SIREN Series Do You Like Most?
          </label>
          <div className="sm:flex">
            {sirenSeries &&
              sirenSeries.map((series) => (
                <div
                  key={series.id}
                  className="flex items-center sm:mr-6 mb-2 sm:mb-0"
                >
                  <input
                    type="radio"
                    id={`sirenSeries-${series.id}`}
                    name="sirenSeries"
                    value={series.id}
                    checked={selectedSIRENSeries === series.id}
                    onChange={(e) =>
                      handleSIRENSeriesClick(Number(e.target.value))
                    }
                    className={`${
                      submissionStatus !== "submitted"
                        ? "cursor-pointer"
                        : "cursor-default"
                    } mr-1 appearance-none bg-white border border-gray-400 border-2 rounded-full w-4 h-4 checked:bg-red-600 checked:border-white`}
                    disabled={submissionStatus === "submitted"}
                  />
                  <label
                    htmlFor={`sirenSeries-${series.id}`}
                    className={`${
                      submissionStatus !== "submitted"
                        ? "cursor-pointer"
                        : "cursor-default"
                    }`}
                  >
                    {series.name}
                  </label>
                </div>
              ))}
          </div>
          {errors.selectedSIRENSeries && (
            <div className="text-red-regular text-sm mt-1">
              {errors.selectedSIRENSeries}
            </div>
          )}
        </div>

        {/* Comment */}
        <div className="mb-7">
          <label htmlFor="comment" className="block text-base sm:text-lg mb-0">
            Write a Comment
          </label>
          <div className="block text-sm mb-2 text-grey">
            We'd love to hear about your love for SIREN! What made you initially
            interested in the game? What did you enjoy about this game? Did you
            beat it on your own, or did you get help? If a new series came out,
            would you try it? Did you find anything interesting about Japanese
            culture or tradition through the game? Comment freely and let us
            hear your thoughts!
          </div>
          <textarea
            id="comment"
            name="comment"
            rows={10}
            minLength={20}
            maxLength={1000}
            placeholder="Write your comment here..."
            value={comment}
            onChange={handleCommentChange}
            disabled={submissionStatus === "submitted"}
            className={
              "bg-gray-100 w-full block text-black px-3 py-2 border border-gray-500 focus:outline-none focus:ring focus:border-blue-300"
            }
            style={{
              background:
                submissionStatus === "submitted"
                  ? "repeating-linear-gradient(45deg, black, black 10px, #444 10px, #444 11px)"
                  : "",
            }}
          ></textarea>
          {errors.comment && (
            <div className="text-red-regular text-sm mt-1">
              {errors.comment}
            </div>
          )}
        </div>

        {/* Honeypot field */}
        <input
          type="text"
          name="honeypot"
          value={honeypotValue}
          onChange={handleHoneypotChange}
          className="hidden"
          tabIndex={-1}
          autoComplete="off"
        />

        {/* Thank-you Message / Submit Button */}
        {submissionStatus === "submitted" ? (
          <div className="mb-5">
            <div className="mb-6">
              <span className="text-center block text-xl">
                Thank you for submitting your comment!
                <i className="block text-sm not-italic">
                  (If you see this comment, you have already posted a comment
                  before.)
                </i>
              </span>
            </div>
            <a
              href="/"
              className="text-center block bg-red-light py-2 px-4 hover:bg-black text-white border border-white transition duration-300"
            >
              Go Back to the First Page
            </a>
          </div>
        ) : (
          <div>
            <div className="w-full mb-10">
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ""}
                onChange={handleRecaptcha}
              />
              {errors.recaptcha && (
                <div className="text-red-regular text-sm mt-1">
                  {errors.recaptcha}
                </div>
              )}
            </div>

            <button
              type="submit"
              className="m-auto block bg-red-light text-white font-bold px-4 py-2 hover:bg-red-regular focus:outline-none focus:bg-red-regular transition duration-300"
              // disabled={
              //   Object.keys(errors).length > 0 ||
              //   !yourName ||
              //   !selectedCharacter ||
              //   !selectedSIRENSeries ||
              //   !comment ||
              //   !reCaptchaToken
              // }
            >
              SUBMIT
            </button>
            {(errors.yourName ||
              errors.selectedCharacter ||
              errors.selectedSIRENSeries ||
              errors.comment ||
              errors.recaptcha) && (
              <div className="text-grey text-sm text-center mt-2">
                Please fix the errors in the form before submitting.
              </div>
            )}
          </div>
        )}

        {submissionStatus === "error" && (
          <div className="text-red-light text-sm mt-2 text-center">
            Sorry, something went wrong. Please try again later.
          </div>
        )}
      </form>
    </div>
  );
};

export default PostForm;
