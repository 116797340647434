import { useEffect, useState } from "react";
import VoteResult from "./VoteResult";
import Omikuji from "./Omikuji";
import axios from "axios";
import SolidBorder from "./SolidBorder";

interface VoteOption {
  image: string;
  label: string;
  votes: number;
}

const RightSidebar = () => {
  const [characterVoteData, setCharacterVoteData] = useState<VoteOption[]>([]);
  const [sirenSeriesVoteData, setSirenSeriesVoteData] = useState<VoteOption[]>(
    []
  );

  useEffect(() => {
    const fetchCharacterVoteData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL_PROD}/characters/votes`
        );

        const characterData = response.data.map((item: any) => ({
          image: item.photo1,
          label: item.name,
          votes: item.votes,
        }));
        setCharacterVoteData(characterData);
      } catch (error) {
        console.error("Error fetching character vote data:", error);
      }
    };

    const fetchSirenSeriesVoteData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL_PROD}/siren-series/votes`
        );
        const sirenSeriesData = response.data.map((item: any) => ({
          image: item.image,
          label: item.name,
          votes: item.votes,
        }));
        setSirenSeriesVoteData(sirenSeriesData);
      } catch (error) {
        console.error("Error fetching SIREN series vote data:", error);
      }
    };

    fetchCharacterVoteData();
    fetchSirenSeriesVoteData();
  }, []);

  return (
    <aside className="w-full">
      <div className="my-16 block sm:hidden">
        <SolidBorder />
      </div>
      <div className="mb-8">
        <Omikuji />
      </div>
      <div className="border-2 border-blue border-solid">
        <VoteResult
          title="Character Popularity Rank"
          data={characterVoteData}
        />
      </div>
      <div className="border-2 border-blue border-solid mt-4">
        <VoteResult
          title="SIREN Series Popularity Rank"
          data={sirenSeriesVoteData}
        />
      </div>
    </aside>
  );
};

export default RightSidebar;
