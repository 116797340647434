import AnchorLink from "react-anchor-link-smooth-scroll";
import { useLocation } from "react-router-dom";

interface PostCommentButtonProps {
  buttonText?: string;
  fontSize?: "small" | "medium" | "large";
  customStyle?: string;
}

const PostCommentButton = ({
  buttonText = "Post Comment",
  fontSize = "medium",
  customStyle,
}: PostCommentButtonProps) => {
  const location = useLocation();
  const currentPath = location.pathname;

  const getFontSize = (): string => {
    switch (fontSize) {
      case "small":
        return "text-sm";
      case "large":
        return "text-lg";
      default:
        return "text-base";
    }
  };

  // Conditionally render based on currentPath
  if (currentPath === "/" || currentPath.startsWith("/page")) {
    return (
      <button
        className={`bg-gradient-to-r from-red-light to-red-medium text-white uppercase font-bold ${getFontSize()} py-2 px-4 transition duration-300 hover:from-red-medium hover:to-red-light ${customStyle}`}
      >
        <AnchorLink href="#form" offset="50">
          {buttonText}
        </AnchorLink>
      </button>
    );
  }

  // Return null if condition is not met
  return null;
};

export default PostCommentButton;
