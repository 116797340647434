export const customDateFormat = (timestamp: string) => {
  const dateObj = new Date(timestamp);
  const year = dateObj.getFullYear();
  const month = dateObj.toLocaleString("en-US", { month: "long" });
  const day = dateObj.getDate();
  const hours = dateObj.getHours();
  
  // Pad minutes and seconds with leading zeros if needed
  const minutes = String(dateObj.getMinutes()).padStart(2, '0');
  const seconds = String(dateObj.getSeconds()).padStart(2, '0');

  const formattedDate = `${year} ${month} ${day} - ${hours}:${minutes}:${seconds}`;
  return formattedDate;
};
