import Container from "./Container";

const Contact = () => {
  return (
    <main>
      <Container>
        <div className="text-sm text-grey text-center">
          For specific inquiries, please use the following email:
          contact[at]forbiddensiren.com
          <br />
          <br />
          While I value your interest and feedback, please understand that I may
          not be able to reply to every message due to time constraints. Thank
          you for understanding.
        </div>
      </Container>
    </main>
  );
};

export default Contact;
